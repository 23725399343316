.single .content {
    p {
        margin: 1rem 0;
    }

    .admonition-content p {
        margin: 1rem 0;
    
        &:first-child {
            margin-top: 0rem;
        }
    
        &:last-child {
            margin-bottom: 0rem;
        }
    }

    .admonition i.icon {
        font-size: 1rem;
        top: 0.85rem;
        // left: 0rem;
    }
}

span.otoge-profile {
    span.rating {
        font-weight: bold;

        &.nijirate {
            background: linear-gradient(180deg, #f88 30%, yellow, cyan 70%);
            background-clip: text;
            -webkit-background-clip: text;
            color: rgba(0, 0, 0, 0.2);
        }

        &.dani {
            background: linear-gradient(180deg, #f66 30%, #f21 70%);
            background-clip: text;
            -webkit-background-clip: text;
            color: rgba(0, 0, 0, 0.2);
        }

        &.reppu {
            background: linear-gradient(180deg, #acc, #4cc);
            color: rgba(20, 80, 80, 1);
            padding: 0 0.2rem;

            &::before {
                content: 'S 06';
                display: inline-block;
                width: 0.8rem;
                line-height: 0.5rem;
                text-align: center;
                vertical-align: middle;
                padding-right: 0.2rem;
                padding-bottom: 0.2rem;
                font-size: 0.7rem;
            }
        }

        &.akapote9 {
            background: linear-gradient(180deg, #424, #636);
            color: white;
            padding: 0 0.2rem;

            &::before {
                content: "    ◆ ◆ ◆\A   ◆ ◆ ◆\A◆ ◆ ◆";
                display: inline-block;
                white-space: pre-wrap;
                width: 1.6rem;
                line-height: 0.3rem;
                vertical-align: middle;
                padding-right: 0.2rem;
                padding-bottom: 0.1rem;
                font-size: 0.3rem;
            }
        }
    }

    span.friend-code {
        text-decoration: underline;
        user-select: all;;
    }
}
